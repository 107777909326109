/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'


const Page404 = () => {
    return (
        <>
            <div css={styles.fourOFour}>
                404
            </div>
            <div css={styles.main}>
                Pfffff not sure what you are looking for
            </div>
        </>
    )
}

const styles = {
    fourOFour: css`
        font-size: 10rem;
        font-family: 'MuktaMahee-ExtraBold';
        color: #000;
        text-align: center;
    `,
    main: css`
        color: #333;
        font-family: 'MuktaMahee-ExtraLight', Arial, sans-serif;
        text-align: center;
    `,
}

export default Page404;
