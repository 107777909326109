/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'


import Timeline from './Timeline.react';
import Header from './Header.react';
import { useState } from 'react';
import { getConsentState, CONSENT_STATE } from './CookieConsentFooter.react';

const getUpdatedHistory = (history, data) => {
    const newPos = history.pos + 1;
    const newStates = [...history.states]
    newStates[newPos] = data;
    // delete the rest of undo history
    newStates.splice(newPos + 1);

    return {
        pos: newPos,
        states: newStates,
    }
}

const saveToLocalStorage = (data) => {
    const consent = getConsentState();
    if (consent !== CONSENT_STATE.CONSENTED) {
        return;
    }

    localStorage.setItem('timelineData', JSON.stringify(data));
}

const MainPage = () => {
    const storedData = localStorage.getItem('timelineData');
    const [allData, setAllData] = useState(storedData == null ? {} : JSON.parse(storedData));
    const [history, setHistory] = useState({ states: [allData], pos: 0 });
    const [status, setStatus] = useState('Loaded.');

    return (
        <div css={styles.page}>
            <Header
                status={status}
                allData={allData}
                onPasteClipboard={(newData) => {
                    saveToLocalStorage(newData);
                    setHistory(getUpdatedHistory(history, newData));
                    setAllData(newData);
                    setStatus('Loaded from clipboard.');
                }}
                history={history}
                onUndo={() => {
                    const newPos = history.pos - 1;
                    if (newPos === -1) {
                        return;
                    }
                    const newData = history.states[newPos]
                    saveToLocalStorage(newData);
                    setAllData(newData);
                    setHistory({ states: history.states, pos: newPos });
                    setStatus('Loaded from undo history.');
                }}
                onRedo={() => {
                    const newPos = history.pos + 1;
                    if (newPos > history.states.length - 1) {
                        return;
                    }
                    const newData = history.states[newPos]
                    saveToLocalStorage(newData);
                    setAllData(newData);
                    setHistory({ states: history.states, pos: newPos });
                    setStatus('Loaded from undo history.');
                }}
            />
            <Timeline allData={allData} setAllData={(data) => {
                saveToLocalStorage(data);
                setHistory(getUpdatedHistory(history, data));
                setAllData(data);
                const consent = getConsentState();
                if (consent !== CONSENT_STATE.CONSENTED) {
                    setStatus('Could not save. Have you accepted cookies?');
                    return;
                }
                setStatus('Saved.');
            
            }} setStatus={setStatus} />
        </div>
    );
}

const styles = {
    page: css`
      font-family: sans-serif;
  `,
}

export default MainPage;
