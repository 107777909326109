/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import { useState, useEffect } from 'react';

const Day = ({ dateString, isToday, isWeekend, data, onDataChange }) => {
    const [localData, setLocalData] = useState(data);

    // let's update local state if parent state is different
    // this can happen when we have just loaded stuff from clipboard
    useEffect(() => {
        if (data !== localData) {
            setLocalData(data);
        }
        // we should only execute if data changes, not if localData changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div css={[
            styles.day,
            isWeekend && styles.weekend,
            (localData != null && localData !== '') && styles.dayWithData,
            isToday && styles.today,
        ]}>
            {dateString}
            <textarea rows={6} css={styles.input} value={localData ?? ''} onChange={(e) => {
                setLocalData(e.target.value);
                onDataChange(e.target.value);
            }} />
        </div>
    );
}

const styles = {
    input: css`
        border: 0;
        width: 95%;
        resize: none;
        background: transparent;
        font-family: sans-serif;
        color: #555;
        margin-top: 4px;
        &:focus {
            outline: none;
        }
    `,
    day: css`
        font-size: 0.8rem;
        width: 150px;
        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
        text-align: center;
        color: #888;
        padding: 5px;
        cursor: pointer;
        &:last-child {
            border-right: 1px solid #eee;
        }
        &:hover {
            // background-color: #ecfaff;
        }
    `,
    dayWithData: css`
        background-color: #ecfaff;
    `,
    today: css`
        background-color: #cef;
    `,
    weekend: css`
        background-color: #fff9e6;
    `,
}

export default Day;
