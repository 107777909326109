/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'

import Day from "./Day.react";

const getNextYear = (startDate, week) => {
    const dayOffset = (week * 7);
    const prevDate = new Date(startDate);
    prevDate.setDate(startDate.getDate() + dayOffset + 7);

    const nextDate = new Date(startDate);
    nextDate.setDate(startDate.getDate() + dayOffset + 14);

    return prevDate.getYear() !== nextDate.getYear() ? nextDate.getFullYear() : null;
}


const isSameDay = (date1, date2) => {
    if (date1 == null || date2 == null) {
        return false;
    }
    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getYear() === date2.getYear()
    );
}

const now = new Date();
const dayOfTheWeek = now.getDay();

const startDay = now.getDate() - (dayOfTheWeek || 7) - 7;
const startDate = new Date((new Date()).setDate(startDay));

const MAX_WEEKS = 30;
const weeks = new Array(MAX_WEEKS).fill(null).map((_, week) => ({
    week,
    nextYear: getNextYear(startDate, week),
    days: new Array(7).fill(null).map((_, day) => {
        const dayOffset = (week * 7) + day + 1;
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + dayOffset);
        const dateString = date.toLocaleDateString(
            'en-gb',
            {
                month: 'short',
                day: 'numeric'
            }
        );

        return {
            day,
            dayOffset,
            date,
            dateKey: date.toDateString(),
            dateString,
            isToday: isSameDay(date, new Date()),
        }
    })
}));

let saveTimer = null;

const Timeline = ({ allData, setStatus, setAllData }) => {
    return (
        <div css={styles.timeline}>
            {weeks.map(w => {
                const lineBreak = w.nextYear != null || w.week === MAX_WEEKS - 1;

                return <div key={w.week}>
                    <div css={[styles.week, lineBreak && styles.lineBreak]}>
                        {w.days.map(d => {
                            return <Day
                                key={d.dateKey}
                                dateString={d.dateString}
                                isToday={d.isToday}
                                isWeekend={d.date.getDay() > 5 || d.date.getDay() === 0}
                                data={allData[d.dateKey] ?? ''}
                                onDataChange={(data) => {
                                    setStatus('Changed...');

                                    clearTimeout(saveTimer);
                                    saveTimer = setTimeout(() => {
                                        const newData = { ...allData, [d.dateKey]: data === '' ? undefined : data };

                                        setAllData(newData);
                                    }, 500);
                                }}
                            />
                        })}
                    </div>

                    {w.nextYear != null && (
                        <div css={styles.year}>
                            {w.nextYear}
                        </div>
                    )}
                </div>
            })}
        </div>
    );
}

const styles = {
    timeline: css`
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    year: css`
        color: #0aF;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        margin: 5px;
    `,
    week: css`
        height: 125px;
        display: flex;
   `,
    lineBreak: css`
       border-bottom: 1px solid #eee;
   `
}

export default Timeline;
