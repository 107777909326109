/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'

import CookieConsentFooter from './CookieConsentFooter.react';
import { useState } from 'react';


const Header = ({ status, allData, onPasteClipboard, history, onUndo, onRedo }) => {
    const [wtfShown, setWtfShown] = useState(false);

    return (
        <div css={styles.header}>
            <h1 css={styles.title}>FlatPlanner</h1>
            <button css={[styles.info, styles.infoLink]} onClick={() => { setWtfShown(!wtfShown); }}>WTF is this?</button>
            {wtfShown &&
                <p css={styles.info}>
                    Here, enjoy a flat, scrollable flow of days. Not that BS 16th century layout most calendar apps give you, with paginated months (honestly, wtf?).
                    It saves data to your browser's localstorage, so use the buttons below to save/load your data. Good bye.
                </p>
            }

            <div css={styles.buttons}>
                <button css={styles.button} onClick={() => {
                    window.navigator.clipboard.writeText(JSON.stringify(allData));
                }}>Copy to clipboard</button>
                <button css={styles.button} onClick={() => {
                    window.navigator.clipboard.readText().then(txt => {
                        const newData = JSON.parse(txt);
                        onPasteClipboard(newData);
                    });
                }}>Paste from clipboard</button>
                <button css={styles.button} onClick={onUndo} disabled={history.pos <= 0}>
                    Undo{history.pos <= 0 ? '' : ` (${history.pos})`}
                </button>
                <button css={styles.button} onClick={onRedo} disabled={history.pos >= history.states.length - 1}>
                    Redo{history.pos >= history.states.length - 1 ? '' : ` (${history.states.length - history.pos - 1})`}
                </button>
            </div>

            <div css={styles.status}>
                {status}
            </div>

            <CookieConsentFooter />

        </div>
    );
}

const styles = {
    header: css`
        position: fixed;
        top: 25px;
        left: 25px;
        width: 150px;
    `,
    title: css`
        color: #7bd;
        font-weight: bold;
        font-size: 1.5rem;
        margin: 0;
    `,
    info: css`
        color: #888;
        font-size: 0.8rem;
    `,
    infoLink: css`
        cursor: help;
        background: none;
        border: 0;
        padding: 0;
    `,
    status: css`
        color: #555;
    `,
    buttons: css`
        margin: 10px 0;
    `,
    button: css`
        padding: 5px;
        margin: 5px 0;
    `,
}

export default Header;
