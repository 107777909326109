/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import { useState } from 'react';

import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";

const cookieName = "cookieConsentState";
const cookieTypes = ['analytics', 'marketing'];

export const CONSENT_STATE = {
    CONSENTED: 'consented',
    DECLINED: 'declined',
    // user hasn't yet accepted or denied. 
    // in this case we show overlay, so can't interact with the page
    UNDETERMINED: 'undetermined'
}

export const getConsentState = () => {
    const acceptedByUser = getCookieConsentValue(cookieName) === 'true';
    const declinedByUser = getCookieConsentValue(cookieName) === 'false';

    if (acceptedByUser) {
        return CONSENT_STATE.CONSENTED;
    }
    if (declinedByUser) {
        return CONSENT_STATE.DECLINED;
    }
    return CONSENT_STATE.UNDETERMINED;
}

/* 
Allows or denies running the tracking scripts embedded in index.html
by setting the type to text/plain or text/javascript
depending on whether the user consented to the use of cookies or not
*/
const toggleScripts = (types, enable) => {
    const scripts = document.getElementsByTagName("script");
    const filtered = Array.from(scripts).filter(s => types.includes(s.getAttribute("data-cookieconsent")));
    filtered.forEach(script => {
        // if already at the desired state, there is nothing to do
        const currentEnabled = script.getAttribute('type') === 'text/javascript';
        if (currentEnabled === enable) {
            return;
        }

        document.head.removeChild(script);
        script.setAttribute('type', enable ? 'text/javascript' : 'text/plain');
        document.head.appendChild(script);
    })
}

const CookieConsentFooter = () => {
    const consentState = getConsentState();

    // ensure we start tracking if user has consented
    toggleScripts(cookieTypes, consentState === CONSENT_STATE.CONSENTED);

    const [consentFooterState, setConsentFooterState] = useState(consentState);

    if (consentFooterState === CONSENT_STATE.CONSENTED) {
        return (
            <span css={styles.footer}>
                You have accepted the use of cookies. <button className='linklike' 
                onClick={() => {
                    resetCookieConsentValue(cookieName);
                    setConsentFooterState(CONSENT_STATE.UNDETERMINED);

                    toggleScripts(cookieTypes, false);
                }}>Click here</button> to revoke permission.
            </span>
        )
    }
    if (consentFooterState === CONSENT_STATE.DECLINED) {
        return (
            <span css={styles.footer}>
                You have declined the use of cookies. <button className='linklike' 
                onClick={() => {
                    resetCookieConsentValue(cookieName);
                    setConsentFooterState(CONSENT_STATE.UNDETERMINED);
                }}>Click here</button> to change your preferences.
            </span>
        )
    }

    return (
        <CookieConsent
            location="bottom"
            cookieName={cookieName}
            expires={999}
            overlay
            buttonText="Accept"
            declineButtonText="Decline"
            style={{ background: "#fff", alignItems: "center", flexDirection: 'column', padding: '0 0 1rem 0' }}
            contentStyle={{ flex: "initial", fontSize: '1rem', textAlign: 'center', marginTop: '1rem' }}
            buttonStyle={{ flex: "initial", color: "#fff", backgroundColor: "#29b3cc", fontSize: "1rem", marginBottom: '2rem', padding: '0.5rem 1.5rem', fontFamily: 'sans-serif' }}
            declineButtonStyle={{ flex: "initial", color: "#ddd", backgroundColor: "#888", fontSize: "1rem", marginBottom: '2rem', padding: '0.5rem 1.5rem', fontFamily: 'sans-serif' }}
            overlayStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
            enableDeclineButton
            onAccept={() => {
                setConsentFooterState(CONSENT_STATE.CONSENTED);
                toggleScripts(cookieTypes, true);
            }}
            onDecline={() => {
                setConsentFooterState(CONSENT_STATE.DECLINED);
            }}
        >
            <div css={styles.consent}>
                Heyy, this page is using cookies. See <a href="/cookiepolicy">cookie policy</a>.
            </div>
        </CookieConsent>
    )
}

const styles = {
    footer: css`
        margin-top: 10px;
        display: block;
        color: #aaa;
        font-size: 0.8rem;

        button {
            font-size: 0.8rem;
            background: none;
            border: 0;
            padding: 0;
            cursor: pointer;
        }
    `,
    consent: css`
        color: #555;
    `
}

export default CookieConsentFooter;
