/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'

const CookiePolicyPage = () => {
    return (
        <div css={styles.main}>
            <h1>Cookie Policy</h1>
            <p>
                This site (flatplanner.net) uses cookies/localstorage. Here I explain what those are and how this site uses them. By using this website and agreeing to this policy, you consent to the use of cookies in accordance with the terms of this policy. You can manage these settings from the footer section on any page.
            </p>

            <h2>How this site uses cookies</h2>
            <p>
                Cookies don’t contain any information that personally identifies you - however, personal information that we store about you may be linked to the information stored in, and obtained from, cookies. The cookies used on this website include are strictly necessary for access and navigation.
            </p>
            <p>
                The site may use the information we obtain from your use of cookies for the following purposes:
            </p>
            <ul>
                <li>
                    to personalize the website for you.
                </li>
            </ul>
            <br />

            <h2>Cookie list</h2>
            <h3>Strictly necessary cookies</h3>
            <p>
                These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences. You can set your browser to block or alert you about these cookies, but some parts of the site will then stop functioning.
            </p>
            <ul>
                <li>
                    <strong>cookieConsentState</strong> - tracks the consent/decline on the use of cookies
                </li>
                <li>
                    <strong>localstorage / timelineData</strong> - stores the data entered into the flat calendar
                </li>
            </ul>
            <br />

            <h2>Opt out of cookies</h2>
            <p>
                If you prefer that we do not collect non-personally identifiable information about your visits for the purpose of delivering targeted advertising, you may opt out by clicking the link in the footer on any page, eg.: <a href="/">the main page</a>.
            </p>
            <p>
                When you opt out, we will place an opt-out cookie on your computer. The opt-out cookie tells us not to collect your information for delivering relevant online advertisements. Please note that if you delete, block, or otherwise restrict cookies, or use a different computer or Internet browser, you will need to renew your opt-out choice.
            </p>

            <h2>Blocking and deleting cookies</h2>
            <p>
                Most browsers allow you to block and/or delete cookies. The way to do this varies between browsers and operating systems, so please see your browser’s help section for more information.
            </p>
            <p>
                Blocking and/or deleting cookies will have a negative impact on the usability of the site.
            </p>

            <h2>Contact me</h2>
            <p>
                If you have any questions or comments about this Cookie Policy, reach out to me at <a href="mailto:hello@levidobson.com">hello@levidobson.com</a>
            </p>
        </div>
    )
}

const styles = {
    main: css`
        font-size: 1rem;
        max-width: 1000px;
        margin: 100px 200px;

        text-size-adjust: 100%; 
        -ms-text-size-adjust: 100%; 
        -moz-text-size-adjust: 100%; 
        -webkit-text-size-adjust: 100%;	

        font-family: 'MuktaMahee-ExtraLight';

        @media only screen and (max-width: 1000px) {
            margin: 100px;
        }
        @media only screen and (max-width: 500px) {
            margin: 100px 50px;
        }

        ul {
            margin-left: 50px;
        }
    `,
}

export default CookiePolicyPage;
